<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm8 md10 text-left>
        <span class="mainHeader">Sales List</span>
      
      </v-flex>
      <v-flex xs12 sm4 md2 text-right>
        <v-text-field 
              v-model="keyword"
              clearable
              class="rounded-xl"
              background-color="white"
              color="#b234a9"
              hide-details
              placeholder="Search"
              outlined
              dense
              append-icon="mdi-magnify"
            >
            </v-text-field>
      </v-flex>
      
          
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 v-if="salesList">
            <v-flex xs12 v-if="salesList.length>0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left tablefont"><b>S.No.</b></th>
                      <th class="text-left tablefont"><b>Estimate No</b></th>
                      <th class="text-left tablefont"><b>Date</b></th>
                      <th class="text-left tablefont"><b>Customer Name</b></th>
                      <th class="text-left tablefont"><b>Mobile Number</b></th>
                      <th class="text-left tablefont"><b>Salesman code</b></th>
                      <th class="text-left tablefont"><b>Action</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in salesList" :key="i" class="table">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>
                        <span v-if="value._id.billNo ">{{ value._id.billNo  }}</span>
                        <span v-else>-</span>
                      </td>
                    
                    
                       <td>
                        <span v-if="value._id.create_date"> {{formatDate(value._id.create_date)}}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value._id.customerName">{{ value._id.customerName }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value._id.mobileNumber">
                          {{ value._id.mobileNumber }}</span
                        >
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value._id.staffCode">
                          {{ value._id.staffCode }}</span
                        >
                        <span v-else>-</span>
                      </td>
                      <td>
                     
                       <v-btn color="#3F053C"
                          dark class="buttons1"
                          small @click="$router.push('/SalesSettlement?no='+value._id._id)">Payment</v-btn>
                        <v-icon v-if="value._id.paymentStatus=='Pending'" title="Edit" class="pl-2" small color="#3F053C" @click="$router.push('/SalesSettlementEdit?no='+value._id._id+'&&billno='+value._id.billNo)">mdi-pencil</v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex xs12  v-else pa-4 align-self-center text-center class="tablefont1">
              No data found
              </v-flex>
          </v-layout>
          <v-layout wrap v-if="salesList">
            <v-flex xs12 pt-4 v-if="salesList.length>0">
              <v-pagination
                small
                color="#3F053C"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page:1,
      Pagelength:null,
      jewelleryId: null,
      adminList: [],
      rolesList: [],
      customerName : null,
      address: null,
      email: null,
      phoneNumber: null,
      newCustomer: null,
      newAddress: null,
      newCity: null,
      newContact: null,
      addcustomerdialogue: false,
      addcat:false,
      positionList: null,
      salesList:[],
      curItem:[],
      curId:null,
      keyword:"",
       editdialogue:false,
deletedialog:false,
rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      phoneRules: [
        (v) => !!v || "phone is required",
        (v) => (v && v.length <= 10) || "invalid number",
        (v) => (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],
    };
  },
  mounted() {
    this.getSales();
  },
  watch:{
    keyword(){
      this.getSales();
    },
    page(){
    this.getSales();
  },
  },
 
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  methods: {
    getSales() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/estimate/getalllist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: 20,
          keyword:this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.salesList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year
        //  +
        // " , " +
        // hours +
        // ":" +
        // minutes +
        // " " +
        // ampm;

      return strTime;
    },
  },
};
</script>
